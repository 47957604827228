import React from "react";
import { useStaticQuery, graphql } from "gatsby";

const Nav = ({pageen, pagelb}) => {
	return (
		<nav className="navbar navbar-light fixed-top navbar-expand-md" id="navbarMain">
			<div className="container-fluid">
				<a className="navbar-brand d-flex align-items-center d-md-none logo-home" href="/lb"><img src="/images/dapit-logo-darkgray.png" alt="Logo, Dapit"/></a>
				<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navMain" aria-controls="navMain" aria-expanded="false" aria-label="Toggle navigation">
					<i className="fas fa-bars"></i>
				</button>
				<div className="justify-content-between collapse navbar-collapse" id="navMain">
					<ul className="navbar-nav d-none d-md-inline">
						<li className="nav-item">
							<a className="logo-home" href="/lb"><img src="/images/dapit-logo-darkgray.png" alt="Logo, Dapit"/></a>
						</li>
					</ul>
					<ul className="navbar-nav align-self-end menu">
						<li className="nav-item d-inline d-md-none">
							<a className="nav-link" aria-current="page" href="/lb">Startsait</a>
						</li>
						<li className="nav-item">
							<a className="nav-link" aria-current="page" href="/lb/portfolio">Portfolio</a>
						</li>
						<li className="nav-item">
							<a className="nav-link" href="/lb/kontakt">Kontakt</a>
						</li>
						<li className="nav-item lang">
							<a className="nav-link" aria-current="page" href={pageen}>EN</a>
						</li>
						<li className="nav-item lang">
							<a className="nav-link" href={"/lb"+pagelb}>LB</a>
						</li>
					</ul>
					<div className="slogan d-block d-md-none">Dapthis, Dapthat, Dapeverything</div>
					<div class="social d-flex justify-content-center d-inline d-md-none">
						<a href="http://www.dapit.lu" target="_blank">
							<i className="fas fa-torii-gate"></i>
						</a>
						<a href="https://t.me/daptele" target="_blank">
							<i className="fab fa-telegram-plane"></i>
						</a>
						<a href="https://t.me/joinchat/T90sf6fhN8BTLZE6" target="_blank">
							<i className="fas fa-rss-square"></i>
						</a>
						<a href="https://www.instagram.com/dapitinsta/" target="_blank">
							<i className="fab fa-instagram"></i>
						</a>
					</div>
					<div class="author d-block d-md-none">
						Entwéckelt an entworf vun
						<a href="http://www.dapit.lu/lb" target="_blank">
							<img src="/images/dapit-logo-darkgray.png" alt="Logo, Dapit"/>
						</a>
					</div>
				</div>
			</div>
		</nav>
	);
};

export default Nav;
