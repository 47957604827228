import React from "react";
import PropTypes from "prop-types";
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from "gatsby";

import Seo from "./seo";
import Nav from "./nav";
import Footer from "./footer";

import '../../assets/bootstrap-5.x/css/bootstrap.min.css';
import '../../assets/fontawesome-5.x/css/all.min.css';
import '../../assets/css/main.css';

import 'jquery';
import 'react-popper';

const Layout = ({ children, seo, pageen, pagelb }) => (
			<>
			<Helmet>
				<link rel="alternate" href={"https://www.dapit.lu"+pageen} hreflang="en"/>
				<link rel="alternate" href={"https://www.dapit.lu/lb"+pagelb} hreflang="lb"/>
			</Helmet>
			<Seo seo={seo} />
			<Nav pageen={pageen} pagelb={pagelb}/>
			<div id="wrapper">
				{children}
			</div>
			<Footer />
			</>
);

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
